@import "color";

/* display 설정 */
.d-block {display: block;}
.d-inline {display: inline-block;}
.d-flex {display: flex;}
.d-none {display: none;}

/* position */
.p-absolute {position: absolute;}
.p-relative {position: relative;}
.p-fixed {position: fixed;}

/* flex-direction */
.f-column {flex-direction: column;}
.f-row {flex-direction: row;}

/* flex 설정 */
.j-left {justify-content: left;}
.j-center {justify-content: center;}
.j-right {justify-content: right;}
.j-between {justify-content: space-between;}
.j-around {justify-content: space-around;}
.a-center {align-items: center;}
.a-start {align-items: flex-start;}
.a-end {align-items: flex-end;}

/* font 설정 */
.fs-9 {font-size: 0.5625rem;}
.fs-10 {font-size: 0.625rem;}
.fs-11 {font-size: 0.6875rem;}
.fs-12 {font-size: 0.75rem;}
.fs-13 {font-size: 0.8125rem;}
.fs-14 {font-size: 0.875rem;}
.fs-15 {font-size: 0.9375rem;}
.fs-16 {font-size: 1rem;}
.fs-17 {font-size: 1.0625rem;}
.fs-18 {font-size: 1.125rem;}
.fs-20 {font-size: 1.25rem;}
.fs-22 {font-size: 1.375rem;}
.fs-24 {font-size: 1.5rem;}
.fs-26 {font-size: 1.625rem;}
.fs-28 {font-size: 1.75rem;}
.fs-30 {font-size: 1.875rem;}
.fs-32 {font-size: 2rem;}
.fs-34 {font-size: 2.125rem;}
.fs-36 {font-size: 2.25rem;}
.fs-38 {font-size: 2.375rem;}
.fs-40 {font-size: 2.5rem;}
.fs-42 {font-size: 2.625rem;}
.fs-44 {font-size: 2.75rem;}
.fs-60 {font-size: 3.75rem;}

.fw-4 {font-weight: 400;} // r
.fw-5 {font-weight: 500;} // m
.fw-6 {font-weight: 600;} // sb
.fw-7 {font-weight: 700;} // b
.tbc-1 {
  font-weight: 700;
  position: relative; /* ::before 요소의 위치를 설정하기 위해 */
  padding: 10px; /* 텍스트와 배경색 간의 간격 조정 */
  display: inline; /* 텍스트의 길이에 맞추기 위해 inline으로 설정 */
}

.tbc-1::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 50%; 
  background: #E5FBF5; /* 하단 절반 색상 */
  z-index: -1; 
  width: 100%; 
  border-radius: 5px; 
}


.ta-center {text-align: center;}
.ta-left {text-align: left;}
.ta-right {text-align: right;}

.ls-0 {letter-spacing: 0;}
.ls-1 {letter-spacing: -0.01em;}
.ls-2 {letter-spacing: -0.02em;}
.ls-3 {letter-spacing: -0.03em;}
.ls-4 {letter-spacing: -0.04em;}

.under {text-decoration: underline;}

/* 색깔 설정 색깔은 color.scss 파일 확인 */
.fc-white {color: $white;}
.fc-black1 {color: $black1;}
.fc-black2 {color: $black2;}
.fc-black3 {color: $black3;}
.fc-black6 {color: $black6;}
.fc-black7 {color: $black7;}
.fc-black8 {color: $black8;}
.fc-mediumGray2{color: $mediumGray2;}
.fc-darkGray2 {color: $darkGray2;}
.fc-darkGray7 {color: $darkGray7;}
.fc-darkGray10 {color: $darkGray10;}
.fc-darkGray14 {color: $darkGray14;}
.fc-darkGray15 {color: $darkGray15;}
.fc-green1 {color: $green1;}
.fc-green2 {color: $green2;}
.fc-green3 {color: $green3;}
.fc-red1{color: $red1;}
.fc-red3{color: $red3;}
.fc-mediumGray{color: $mediumGray2;}
.bc-white {background-color: $white;}
.bc-lightGray5 {background-color: $lightGray5}
.bc-lightGray7{background-color: $lightGray7;}
.fc-lightGray2 {color: $lightGray2;}
.fc-lightGray16 {color: $lightGray16;}
.fc-mediumGray1 {color: $mediumGray1;}
.fc-mediumGray2 {color: $mediumGray2;}
.fc-mediumGray4 {color: $mediumGray4;}
.fc-mediumGray9 {color: $mediumGray9;}
.fc-mediumGray11 {color: $mediumGray11;}
.fc-mediumGray16 {color: $mediumGray16;}
.fc-mediumGray29 {color: $mediumGray29;}
.fc-darkGray3 {color: $darkGray3;}
.fc-darkGray4 {color: $darkGray4;}
.fc-darkGray7 {color: $darkGray7;}
.fc-darkGray8 {color: $darkGray8;}
.fc-darkGray9 {color: $darkGray9;}
.fc-darkGray11 {color: $darkGray11;}
.fc-darkGray14 {color: $darkGray14;}
.fc-darkGray18 {color: $darkGray18;}
.fc-darkGray21 {color: $darkGray21;}
.fc-darkGray23 {color: $darkGray23;}
.fc-darkGray24 {color: $darkGray24;}
.fc-green2 {color: $green2;}
.fc-green3 {color: $green3;}
.fc-green8 {color: $green8;}
.fc-green10 {color: $green10;}
.fc-green11 {color: $green11;}
.fc-green17 {color: $green17;}
.fc-red6 {color: $red6;}
.fc-blue1 {color: $blue1;}
.fc-blue4 {color: $blue4;}
.bc-white {background-color: $white;}
.bc-gray1 {background-color: $gray1;}
.bc-lightGray8 {background-color: $lightGray8;}
.bc-lightGray9 {background-color: $lightGray9;}
.bc-lightGray10 {background-color: $lightGray10;}
.bc-lightGray11 {background-color: $lightGray11;}
.bc-lightGray12 {background-color: $lightGray12;}
.bc-lightGray13 {background-color: $lightGray13;}
.bc-lightGray18 {background-color: $lightGray18;}
.bc-mediumGray9 {background-color: $mediumGray9;}
.bc-mediumGray23 {background-color: $mediumGray23;}
.bc-darkGray6 {background-color: $darkGray6;}
.bc-darkGray7 {background-color: $darkGray7;}
.bc-darkGray20 {background-color: $darkGray20;}
.bc-green1 {background-color: $green1;}
.bc-green4 {background-color: $green4;}
.bc-green5 {background-color: $green5;}
.bc-green8 {background-color: $green8;}
.bc-red5 {background-color: $red5;}
.bc-darkGray11 {background-color: $darkGray11;}
.bc-darkGray16 {background-color: $darkGray16;}
.bc-darkGray22 {background-color: $darkGray22;}
.bc-blue8 {background-color: $blue8;}
.op-60 {opacity: 60%;}

/* 말줄입 설정 */
.ellipsis-1 {display: block; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
.ellipsis-2 {display: block; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 2; -webkit-box-orient: vertical; white-space: normal;}
.ellipsis-3 {display: block; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 3; -webkit-box-orient: vertical; white-space: normal;}
.ellipsis-30 {display: block; overflow: hidden; text-overflow: ellipsis; display: -webkit-box; -webkit-line-clamp: 30; -webkit-box-orient: vertical; white-space: normal;}

/* 자동 줄바꿈 설정 */
.wb-all {word-break: keep-all;}

/* 박스 굴곡 설정 */
.br-3 {border-radius: 3px;}
.br-4 {border-radius: 4px;}
.br-5 {border-radius: 5px;}
.br-6 {border-radius: 6px;}
.br-7 {border-radius: 7px;}
.br-8 {border-radius: 8px;}
.br-9 {border-radius: 9px;}
.br-10 {border-radius: 10px;}
.br-12 {border-radius: 12px;}
.br-27 {border-radius: 27px;}
.br-31 {border-radius: 31px;}
.br-35 {border-radius: 35px;}
.br-45 {border-radius: 45px;}

/* 테두리 설정 */
.ba-1-mediumGray8 { border: 1px solid $mediumGray8; }
.ba-1-mediumGray9 { border: 1px solid $mediumGray9; }
.ba-1-mediumGray23 { border: 1px solid $mediumGray23; }
.ba-1-mediumGray25 { border: 1px solid $mediumGray25; }
.ba-1-mediumGray28 { border: 1px solid $mediumGray28; }
.ba-1-lightGray15 { border: 1px solid $lightGray15; }
.ba-1-lightGray20 { border: 1px solid $lightGray20; }
.ba-1-green1 { border: 1px solid $green1; }
.ba-2-green1 { border: 2px solid $green1; }
.ba-2-green8 { border: 2px solid $green8; }
.ba-2-lightGray1 {border: 2px solid $lightGray1;}
.ba-2-lightGray8 {border: 2px solid $lightGray8;}

.bt-1-lightGray14 { border-top: 1px solid $lightGray14; }
.bt-1-mediumGray14 { border-top: 1px solid $mediumGray14; }
.bt-1-mediumGray18 { border-top: 1px solid $mediumGray18; }
.bt-1-mediumGray19 { border-top: 1px solid $mediumGray19; }
.bt-1-mediumGray27 { border-top: 1px solid $mediumGray27; }

.bb-1-lightGray14 { border-bottom: 1px solid $lightGray14; }
.bb-1-lightGray17 { border-bottom: 1px solid $lightGray17; }
.bb-1-lightGray19 { border-bottom: 1px solid $lightGray17; }
.bb-1-mediumGray14 { border-bottom: 1px solid $mediumGray14; }
.bb-1-mediumGray18 { border-bottom: 1px solid $mediumGray18; }
.bb-1-mediumGray19 { border-bottom: 1px solid $mediumGray19; }
.bb-1-mediumGray25 { border-bottom: 1px solid $mediumGray25; }
.bb-1-mediumGray27 { border-bottom: 1px solid $mediumGray27; }
.bb-1-mediumGray30 { border-bottom: 1px solid $mediumGray30; }
.bb-5-green8 { border-bottom: 5px solid $green8; }

.bl-1-mediumGray25 { border-left: 1px solid $mediumGray25; }

/* 간격 설정 */

// .mr-0 {margin-right: 0;}
// .mr-5 {margin-right: 5px;}
// .mr-8 {margin-right: 8px;}
// .mr-10 {margin-right: 10px;}
// .mr-15 {margin-right: 15px;}
// .mr-16 {margin-right: 16px;}
// .mr-18 {margin-right: 18px;}
// .mr-20 {margin-right: 20px;}
// .mr-23 {margin-right: 23px;}
// .mr-25 {margin-right: 25px;}
// .mr-26 {margin-right: 26px;}
// .mr-28 {margin-right: 28px;}
// .mr-30 {margin-right: 30px;}
// .mr-35 {margin-right: 35px;}
// .mr-40 {margin-right: 40px;}
// .mr-42 {margin-right: 42px;}
// .mr-45 {margin-right: 45px;}
// .mr-50 {margin-right: 50px;}
// .mr-120 {margin-right: 120px;}
// .mr-165 {margin-right: 160px;}
// .mr-190 {margin-right: 190px;}
// .mr-200 {margin-right: 200px;}

// .ml-0 {margin-left: 0;}
// .ml-4 {margin-left: 4px;}
// .ml-5 {margin-left: 5px;}
// .ml-6 {margin-left: 6px;}
// .ml-8 {margin-left: 8px;}
// .ml-10 {margin-left: 10px;}
// .ml-15 {margin-left: 15px;}
// .ml-16 {margin-left: 16px;}
// .ml-17 {margin-left: 17px;}
// .ml-18 {margin-left: 18px;}
// .ml-20 {margin-left: 20px;}
// .ml-21 {margin-left: 21px;}
// .ml-23 {margin-left: 23px;}
// .ml-24 {margin-left: 24px;}
// .ml-25 {margin-left: 25px;}
// .ml-26 {margin-left: 26px;}
// .ml-30 {margin-left: 30px;}
// .ml-35 {margin-left: 35px;}
// .ml-40 {margin-left: 40px;}
// .ml-42 {margin-left: 42px;}
// .ml-45 {margin-left: 45px;}
// .ml-50 {margin-left: 50px;}
// .ml-90 {margin-left: 90px;}
// .ml-100{margin-left: 100px;}
.m-auto {margin: 0 auto}

@mixin mr($number) {
  margin-right: calc(#{$number} / 600 * 100%);
}

@media (max-width: 600px) {
  @mixin mr($number) {
    margin-right: calc(#{$number} / 100vw * 100%);
  }
}

@for $i from 1 through 200 {
  .mr-#{$i} {
    @include mr($i);
  }
}

@mixin ml($number) {
  margin-left: calc(#{$number} / 600 * 100%);
}

@media (max-width: 600px) {
  @mixin ml($number) {
    margin-left: calc(#{$number} / 100vw * 100%);
  }
}

@for $i from 1 through 200 {
  .ml-#{$i} {
    @include ml($i);
  }
}


.mt-0 {margin-top: 0;}
.mt-4 {margin-top: 4px;}
.mt-5 {margin-top: 5px;}
.mt-8 {margin-top: 8px;}
.mt-10 {margin-top: 10px;}
.mt-13 {margin-top: 13px;}
.mt-15 {margin-top: 15px;}
.mt-16 {margin-top: 16px;}
.mt-19 {margin-top: 19px;}
.mt-20 {margin-top: 20px;}
.mt-22 {margin-top: 22px;}
.mt-23 {margin-top: 23px;}
.mt-24 {margin-top: 24px;}
.mt-25 {margin-top: 25px;}
.mt-27 {margin-top: 27px;}
.mt-28 {margin-top: 28px;}
.mt-29 {margin-top: 29px;}
.mt-30 {margin-top: 30px;}
.mt-35 {margin-top: 35px;}
.mt-40 {margin-top: 40px;}
.mt-45 {margin-top: 45px;}
.mt-50 {margin-top: 50px;}
.mt-126 {margin-top: 126px;}
.mt-150 {margin-top: 150px;}

.mb-0 {margin-bottom: 0;}
.mb-2 {margin-bottom: 2px;}
.mb-5 {margin-bottom: 5px;}
.mb-8 {margin-bottom: 8px;}
.mb-10 {margin-bottom: 10px;}
.mb-12 {margin-bottom: 12px;}
.mb-15 {margin-bottom: 15px;}
.mb-16 {margin-bottom: 16px;}
.mb-17 {margin-bottom: 17px;}
.mb-18 {margin-bottom: 18px;}
.mb-20 {margin-bottom: 20px;}
.mb-22 {margin-bottom: 22px;}
.mb-23 {margin-bottom: 23px;}
.mb-24 {margin-bottom: 24px;}
.mb-25 {margin-bottom: 25px;}
.mb-28 {margin-bottom: 28px;}
.mb-30 {margin-bottom: 30px;}
.mb-35 {margin-bottom: 35px;}
.mb-39 {margin-bottom: 39px;}
.mb-40 {margin-bottom: 40px;}
.mb-45 {margin-bottom: 45px;}
.mb-50 {margin-bottom: 50px;}
.mb-80 {margin-bottom: 80px;}

.ma-23 {margin:23px;}

.pr-0 {padding-right: 0;}
.pr-5 {padding-right: 5px;}
.pr-10 {padding-right: 10px;}
.pr-12 {padding-right: 12px;}
.pr-15 {padding-right: 15px;}
.pr-20 {padding-right: 20px;}
.pr-25 {padding-right: 25px;}
.pr-26 {padding-right: 26px;}
.pr-30 {padding-right: 30px;}
.pr-35 {padding-right: 35px;}
.pr-40 {padding-right: 40px;}
.pr-45 {padding-right: 45px;}
.pr-50 {padding-right: 50px;}

.pl-0 {padding-left: 0;}
.pl-4 {padding-left: 4px;}
.pl-5 {padding-left: 5px;}
.pl-10 {padding-left: 10px;}
.pl-12 {padding-left: 12px;}
.pl-15 {padding-left: 15px;}
.pl-16 {padding-left: 16px;}
.pl-18 {padding-left: 18px;}
.pl-20 {padding-left: 20px;}
.pl-25 {padding-left: 25px;}
.pl-26 {padding-left: 26px;}
.pl-30 {padding-left: 30px;}
.pl-35 {padding-left: 35px;}
.pl-39 {padding-left: 39px;}
.pl-40 {padding-left: 40px;}
.pl-45 {padding-left: 45px;}
.pl-50 {padding-left: 50px;}

.pt-0 {padding-top: 0;}
.pt-5 {padding-top: 5px;}
.pt-10 {padding-top: 10px;}
.pt-15 {padding-top: 15px;}
.pt-20 {padding-top: 20px;}
.pt-24 {padding-top: 24px;}
.pt-25 {padding-top: 25px;}
.pt-28 {padding-top: 28px;}
.pt-30 {padding-top: 30px;}
.pt-35 {padding-top: 35px;}
.pt-40 {padding-top: 40px;}
.pt-45 {padding-top: 45px;}
.pt-50 {padding-top: 50px;}

.pb-0 {padding-bottom: 0;}
.pb-5 {padding-bottom: 5px;}
.pb-10 {padding-bottom: 10px;}
.pb-15 {padding-bottom: 15px;}
.pb-20 {padding-bottom: 20px;}
.pb-25 {padding-bottom: 25px;}
.pb-30 {padding-bottom: 30px;}
.pb-32 {padding-bottom: 32px;}
.pb-35 {padding-bottom: 35px;}
.pb-38 {padding-bottom: 38px;}
.pb-40 {padding-bottom: 40px;}
.pb-45 {padding-bottom: 45px;}
.pb-50 {padding-bottom: 50px;}
.pb-70 {padding-bottom: 70px;}

.pa-10 {padding: 10px;}
.pa-24 {padding: 24px;}


/* scrollbar 설정*/
/* WebKit 기반 브라우저 (Chrome, Safari, Edge 등) */
.scrollbar::-webkit-scrollbar {
  width: 3px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #D9D9D9;
}

.scrollbar::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Firefox */
.scrollbar {
  scrollbar-width: thin; /* 스크롤바 두께 설정 */
  scrollbar-color: #D9D9D9 transparent; /* 스크롤바 색상 및 트랙 색상 설정 */
}


.w-35 {width: 35%;}
.w-50 {width: 50%;}
.w-75 {width: 65%;}
.w-100 {width: 100%;}
.borderBox {box-sizing: border-box}

.h-70 {height: 70px;}
.h-80 {height: 80px;}
.h-940{height: 940px;}

.pointer {cursor: pointer;}

.o-hidden {overflow:hidden;}
.keep-all {word-break: keep-all;}