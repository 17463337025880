@import "color";

@font-face {
  font-family: "pretendard";
  src: url("../font/PretendardVariable.woff2") format("woff2");
  font-weight: 100 900;
  font-style: normal;
  font-display: swap;
}

body {
  margin: 0;
  padding: 0;
  font-family: "pretendard", serif;
}

h1, h2, h3, h4, h5, p, thead, tbody, tr, th, td, button, a, label, input, div, ul, li, span {
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-weight: inherit;
  letter-spacing: inherit;
  color: inherit;
  text-align: inherit;
}

input {
  outline: none;
}

textarea {
  resize: none;
  outline: none;
}

li, ol {
  list-style-type: none;
}

a, select {
  text-decoration-line: none;
}

button {
  cursor: pointer;
  border: none;
  background-color: transparent;
  text-align: center;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

img {
  width: 100%;
  height: auto;
}

/* inner */
.inner {
  max-width: 552px;
  width: 92vw;
  margin: 0 auto;
}
@media screen and (max-width: 360px) {
  .inner {
    width: calc(100% - 20px);
  }
}

/* commonBarBtn */
.commonBarBtn {
  height: 70px;
}
@media screen and (max-width: 400px) {
  .commonBarBtn {
    height: 50px;
  }
}



/* 로딩 이미지 */
@keyframes loadingAnimation {
  0% { transform: translate(-50%,-50%) rotate(0deg); }
  100% { transform: translate(-50%,-50%) rotate(360deg); }
}
.loadingRolling {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: $white;
  border-radius: 20px;

  .loadingBox {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */

    div {
      position: absolute;
      width: 120px;
      height: 120px;
      border: 20px solid $black1;
      border-top-color: transparent;
      border-radius: 50%;
      box-sizing: content-box;
      animation: loadingAnimation 2s linear infinite;
      top: 100px;
      left: 100px
    }
  }
}