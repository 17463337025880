$black1: #000000;
$black2: #111111;
$black3: #121212;
$black4: #181818;
$black5: #1F1F1F;
$black6: #202020;
$black7: #2E2E2E;
$black8: #1A1A1A;

$darkGray1: #232323;
$darkGray2: #272727;
$darkGray3: #303030;
$darkGray4: #313131;
$darkGray5: #323232;
$darkGray6: #353535;
$darkGray7: #363636;
$darkGray8: #3C3C3C;
$darkGray9: #424242;
$darkGray10: #474747;
$darkGray11: #494949;
$darkGray12: #4F4F4F;
$darkGray13: #535353;
$darkGray14: #555555;
$darkGray15: #565656;
$darkGray16: #5A5C5B;
$darkGray17: #5B5B5B;
$darkGray18: #5C5C5C;
$darkGray19: #5F6368;
$darkGray20: #636363;
$darkGray21: #666666;
$darkGray22: #3D3D3D;
$darkGray23: #333333;
$darkGray24: #5D6064;

$gray1: #818386;
$gray2: #5F6368;

$mediumGray1: #6B6B6B;
$mediumGray2: #767676;
$mediumGray3: #777777;
$mediumGray4: #7E7E7E;
$mediumGray5: #818386;
$mediumGray6: #858585;
$mediumGray7: #8E8E8E;
$mediumGray8: #989898;
$mediumGray9: #A0A0A0;
$mediumGray10: #ABABAB;
$mediumGray11: #B0B0B0;
$mediumGray12: #B6B6B6;
$mediumGray13: #BEBEBE;
$mediumGray14: #BFBFBF;
$mediumGray15: #C2C2C2;
$mediumGray16: #C4C4C4;
$mediumGray17: #C5C5C5;
$mediumGray18: #CACACA;
$mediumGray19: #CDCDCD;
$mediumGray20: #CECECE;
$mediumGray21: #D0D0D0;
$mediumGray22: #D2D2D2;
$mediumGray23: #D3D3D3;
$mediumGray24: #D9D9D9;
$mediumGray25: #DBDBDB;
$mediumGray26: #DCDCDC;
$mediumGray27: #ACACAC;
$mediumGray28: #EDEDED;
$mediumGray29: #7D7D7D;
$mediumGray30: #D8D8D8;

$lightGray1: #E4E4E4;
$lightGray2: #E6E6E6;
$lightGray3: #E7E7E7;
$lightGray4: #ECECEC;
$lightGray5: #EFFFF8;
$lightGray6: #F0F0F0;
$lightGray7: #F1F1F1;
$lightGray8: #F5F5F5;
$lightGray9: #F7F7F7;
$lightGray10: #F8F8F8;
$lightGray11: #F8FFFC;
$lightGray12: #F9FAF9;
$lightGray13: #F9F9F9;
$lightGray14: #E9E9E9;
$lightGray15: #E1E1E1;
$lightGray16: #E2E2E2;
$lightGray17: #E0E0E0;
$lightGray18: #FDFDFD;
$lightGray19: #C0C0C0;
$lightGray20: #AAAAAA;

$white: #FFFFFF;

$red1: #FF0000;
$red2: #E00000;
$red3: #DB0000;
$red4: #CD1D3A;
$red5: #F4571E;
$red6: #FF6A00;
$red7: #E04300;
$red8: #F11E61;
$red9: #FFCC33;
$red10: #FF6A00;

$blue1: #006FFF;
$blue2: #0032D4;
$blue3: #165CAD;
$blue4: #0078BC;
$blue5: #0065A9;
$blue6: #435060;
$blue7: #495259;
$blue8: #3B5A82;

$green1: #0B8E70;
$green2: #007C59;
$green3: #00A865;
$green4: #00967D;
$green5: #00AA44;
$green6: #009554;
$green7: #00937A;
$green8: #04AB68;
$green9: #00A361;
$green10: #00B661;
$green11: #00A57F;
$green12: #00C878;
$green13: #007D61;
$green14: #51CFB2;
$green15: #34A88D;
$green16: #E7FBF6;
$green17: #008E4C;
