@import "color";

/* home 설정 */
#home {
  .todayBox {
    .fc-green2 {
      margin-left: 1.5%;
    }
    .fs-20 {
      transform: translateY(-2px);
    }
  }

  .weatherBox {
    // width: 50%;

    &.line {
      border-right: 1px solid $mediumGray26;
    }

    img {
      width: 29.74%;
      aspect-ratio: 1;
      object-fit: contain;
    }

    span {
      border-radius: 43px;
    }
  }

  .homeSlider {
    overflow-y: hidden;

  }

  #homeBtnBox {
    button {
      aspect-ratio: 1.1 / 1;
    }
  }

  .homeFooter {
    padding-top: 30px;
    padding-bottom: 90px;
  }
}