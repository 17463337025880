#tradeCenterDetail {
  .centerX {
    margin: 0 3px;
  }
  .dayBtn {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    margin-right: 10px;
    color: #999999;
    background-color: #F5F5F5;

    &.active {
      color: #FFFFFF;
      background-color: #CF484A;
    }
  }
  .dayTable {
    width: 100%;

    th {
      width: calc(100% / 7);
      height: 50px;
    }
    
    td {
      border: 1px solid #FFFFFF;
      height: 78px;
      color: #FFFFFF;
      background-color: #6B6C79;
      cursor: pointer;

      &.active {
        background-color: #CF484A;
      }

      &.none {
        background: #FFFFFF;
      }

      &.disabled {
        color: #767676;
        background-color: #F8F8F8;
        text-decoration-line: line-through ;
      }
    }
  }
  .checkBox {
    width: 100%;
    padding: 15px 20px;
    box-sizing: border-box;
    border: 1px solid #BFBFBF;
    border-radius: 5px;
    cursor: pointer;

    &.active {
      border: 1px solid #0B8E70;

      rect {
        fill: #0B8E70;
      }
    }
  }

  .radioBox {
    width: 100%;

    .back {
      width: 28px;
      height: 28px;
      margin-right: 10px;
      background-image: url("../icon/radio.svg");
    }

    input:checked + label {
      .back {
        background-image: url("../icon/radio-fill.svg");
      }
    }
  }
}

.termsPopup {
  &.popupContainer {
    .popupBody {
      padding-top: 35px;
      max-height: 100dvh;
      box-sizing: border-box;

      .xBtn {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
      }

      .textArea {
        padding: 10px 0;
        max-height: calc(100dvh - 179px);
        box-sizing: border-box;
        overflow-y: auto;

        .circle {
          width: 6px;
          margin-top: 2.5%;
          margin-right: 5px;
        }
        p {
          width: calc(100% - 11px);
          line-height: 1.5em;
        }
      }

      .btnBox {
        padding-top: 10px;
        box-sizing: border-box;

        .checkBtn {
          width: 100%;
          height: 50px;
          background-color: #0B8E70;
          border-radius: 12px;
        }
      }
    }
  }
}
#resultPopup {
  &.popupContainer {
    .textArea {
      padding: 50px 0 100px 0;
    }
  }
}
@media screen and (max-width: 420px) {
  #tradeCenterDetail {
    .dayBtn {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    .dayTable {
      td {
        height: 50px;
      }
    }
    .checkBox {
      padding: 10px 15px;
    }
  }
}

.centerListElement {
  &.active {
    .centerListHiddenBox {
      display: inline-block;
    }
    .arrow {
      transform: rotate(180deg);
    }
  }
}

.centerItems {
  .categoryImageBox {
    width: 65px;
    height: 65px;
    border-radius: 100%;
    margin-right: 16px;
    overflow: hidden;
  }
  .itemList {
    background-color: #F8F8F8;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 4px;

    .itemBox {
      padding: 24px 0;
      cursor: pointer;
    }

    .imageBox {
      max-width: 160px;
      width: 32%;
      height: 160px;
      border: 1px solid #D7DAE0;
      border-radius: 5px;
      box-sizing: border-box;
      overflow: hidden;
    }
    .textBox {
      width: calc(68% - 15px);
    }
  }
}

#tradeCenterSelect {
  .btnList {
    button {
      width: calc((100% - 25px) / 2);
      height: 270px;
      padding: 0 10px;
      box-sizing: border-box;
      box-shadow: 0 3px 10px 0 rgba(0,0,0,0.17);

      .textBox {
        img {
          width: 10%;
        }
        p {
          width: 88%;
        }
        &.address {
          img {
            transform: translateY(2px);
          }
        }
      }
    }
  }
}

.bottomBtnBox {
  height: 110px;
}

#tradeCenterList {
  .messageBox {
    width: calc(100% - 52px);
    height: 68px;
    line-height: 68px;
    background-color: rgba(35,35,35,0.9);
    border-radius: 9px;
    position: absolute;
    top: calc(50% - 34px);
    left: 26px;
  }
}

#rentList {
  .hintBox {
    p {line-height: 1.5rem}
  }

  .rentBox {
    height: 160px;
    border-top: 1px solid #CDCDCD;
    border-bottom: 1px solid #CDCDCD;
    box-sizing: border-box;

    .imgBox {
      width: 160px;
      height: 158px;
      overflow: hidden;
    }

    .textBox {
      width: calc(100% - 160px);
      min-height: 160px;
      border-left: 1px solid #CDCDCD;
      box-sizing: border-box;
      padding: 15px;

      .name {
        margin-top: 8px;
        margin-bottom: 12px;
      }

      .coinBox {
        margin-bottom: 4px;
      }

      .icon {
        width: 20px;
        height: auto;
        margin-right: 4px;
      }
    }
  }
}
