@import "color";

.inquiryItem {
  border-top: 1px solid $mediumGray25;

  &.first {
    border-top: none;
  }

  .status {
    max-width: 101px;
    width: 17%;
  }
}

.inquiryDetail {
  #inquiryPopup {
    .fc-green1 {
      font-weight: 700;
      color: $black1;
    }
  }

  .btnBox {
    .removeBtn {
      width: 90px;
      height: 70px;
    }
    .setBox {
      width: calc(100% - 100px);
    }
  }
}


@media screen and (max-width: 400px) {
  #Inquiry {
    .content6 {
      height: calc(100% - 145px);
    }
  }

  .inquiryDetail {
    .btnBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;

      .removeBtn {
        height: 50px;

        img {
          width: auto; // 버튼 안의 이미지도 너비에 맞춰줌
          height: 100%;
        }
      }


      .setBox {
        width: calc(100% - 75px);

        img {
          width: auto;
          height: 100%;
        }
      }
    }
  }
}